<template>
  <div class="material-action">
    <template v-if="!isLoading">
      <header v-if="isPage" class="sticky top-[90px] z-[1111] bg-[#f8f8f8bf]">
        <div class="back" @click="back">
          <UIcon name="arrow-left" />
        </div>

        <input type="text" class="title-material" v-model="material.title" placeholder="Введите название материала" />

        <div class="delete" @click="isViewMaterial = true">
          <UIcon name="eye" class="w-5 h-5" />
        </div>
        <div v-if="!isCreate" class="delete" @click="isDelete = true">
          <UIcon name="delete" />
        </div>

        <UButton @click="actionMaterial">
          {{ isCreate ? 'Создать материал' : 'Сохранить материал'}}
        </UButton>
      </header>

      <div class="content-material-action">
        <div class="description">
          <textarea v-model="material.description" placeholder="Описание материала">
          </textarea>
        </div>
        <UTags v-model="material.tags" />
        <FullText v-model="material.body" />
      </div>
    </template>
    <UPreloader v-else />

    <UModal v-model="isDelete" width="400px">
      <div class="text-3xl text-center mb-6 !font-medium">Удалить материал?</div>

      <div class="flex justify-end !mt-4">
        <UButton @click="isDelete = false" variant="gray" class="mr-4">Закрыть</UButton>
        <UButton @click="deleteMaterial">Удалить</UButton>
      </div>
    </UModal>

    <UModal v-model="isViewMaterial" width="600px">
      <MaterialView :body="material.body" />
    </UModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { coreApi } from '@/api'
import FullText from '@/components/base/FullText'
import UButton from '@/components/redesign/ui/UButton'
import UPreloader from '@/components/redesign/ui/UPreloader'
import UTags from '@/components/redesign/ui/UTags'
import UModal from "@/components/redesign/ui/UModal.vue";
import MaterialView from "@/components/redesign/materials/view/MaterialView.vue";

export default {
  name: 'MaterialPage',
  components: {
    UModal,
    UButton,
    UPreloader,
    FullText,
    UTags,
    MaterialView
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDelete: false,
      isLoading: true,
      isViewMaterial: false,
      material: {
        tags: [],
      },
    };
  },
  computed: {
    ...mapState('main', ['user']),
    isCreate() {
      return !(this.id > 0);
    },
  },
  methods: {
    async deleteMaterial() {
      await coreApi.articleDelete(this.id);
      this.back();
    },
    back() {
      this.$router.replace(`/materials`);
    },
    async actionMaterial() {
      const params = {
        user: this.user,
        article: {
          ...this.material,
          body: JSON.stringify(this.material.body || []),
          tags: JSON.stringify(this.material?.tags || []),
        },
      };

      if (this.material.id) {
        await coreApi.articleUpdate(params);
      } else {
       await coreApi.articleCreate(params);
      }

      if (this.isPage) this.$router.replace(`/materials/`)
    },
    async init() {
      this.isLoading = true;
      if (this.id  > 0) {
        let material = await coreApi.article({ id: this.id });

        console.log('material', material);

        if (material) {
          this.material = {
            ...material,
            body: JSON.parse(material.body || []),
            tags: material.tags ? JSON.parse(material.tags) : []
          };
        } else {
          this.back();
        }
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
.material-action {
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .back,
    .delete {
      min-width: 48px;
      width: 48px;
      height: 48px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 16px;
      cursor: pointer;

      .icon {
        fill: #A9A9A9;
      }

      &:hover {
        .icon {
          fill: #777777;
        }
      }
    }

    .title-material {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      background: #fff;
      border-radius: 8px;
      margin-right: 16px;
      cursor: pointer;
    }

    .button {
      height: 48px;
      max-width: 220px;
    }
  }

  .content-material-action {
    .description {
      margin: 0 auto;
      width: 850px;
      margin-bottom: 16px;
      height: 90px;
      background: #fff;
      cursor: pointer;
      overflow: hidden;
      border-radius: 8px;

      textarea {
        outline: none;
        border-radius: 8px;
        width: 100%;
        height: 90px;
        padding: 16px;
      }
    }

    .full-text-inner {
      max-width: 850px;
      margin: 0 auto;
      background: white;
      border-radius: 8px;
      padding: 16px;
      overflow: inherit;
    }

    .utags {
      margin: 0 auto;
      width: 850px;
      margin-bottom: 16px;
    }
  }
}
</style>
