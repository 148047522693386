<template>
  <div class="quote">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'EditorQuote',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    text() {
      return this.data.data.text.replaceAll(/&nbsp;/gi, ' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  margin: 15px 0;
  padding: 10px 0 10px 15px;
  border-left: 4px solid var(--main);
}
</style>
