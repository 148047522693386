<template>
  <div class="material-page">
    <MaterialAction :id="id" isPage />
  </div>
</template>

<script>
import router from '@/router';
import MaterialAction from '@/components/redesign/materials/MaterialAction';

export default {
  name: 'MaterialPage',
  components: { MaterialAction },
  computed: {
    id() {
      return router.currentRoute.params.id;
    }
  },
}
</script>

<style lang="scss">
.material-page {
  width: 100%;
}
</style>
