<template>
  <div class="!mb-4">
    <img v-if="!isVideo" :src="src" />
    <VuePlyr v-else>
      <video>
        <source :src="src" type="video/mp4" />
      </video>
    </VuePlyr>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
export default {
  name: 'ImageWrapper',
  components: {
    VuePlyr
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isVideo() {
      const ext = this.data.data.file.url.match(/\.([^.]+)$/)?.[1];
      return ext === 'mp4';
    },
    src() {
      return this.data.data.file.url;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  padding: 20px;
  border-radius: 24px;
}
</style>
