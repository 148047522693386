<template>
  <p v-html="text" :style="{ 'text-align': alignment }"></p>
</template>

<script>
export default {
  name: 'EditorParagraph',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    text() {
      return this.data.data.text;
    },
    alignment() {
      return this.data.tunes.alignment.alignment;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 15px;
}
</style>
