<template>
  <div class="!pl-8 !pb-4">
    <ul :style="{ 'list-style-type': type }">
      <li v-for="(item, index) of list" :key="index" class="!pb-3">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EditorList',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    list() {
      return this.data.data.items;
    },
    type() {
      return this.data.data.style === 'unordered' ? 'disc' : 'decimal';
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin: 15px 0;

  ul {
    padding: 20px;

    li {
      padding-bottom: 16px;
    }
  }
}
</style>
