<template>
  <div>
    <div class="text-2xl font-medium !mb-4">Предпросмотр материала</div>

    <div v-if="content.length">
      <component
        :is="item.type"
        v-for="(item, index) of content"
        :key="index"
        :data="item"
      />
    </div>
  </div>
</template>
<script>
import Paragraph from '@/components/redesign/materials/view/modules/Paragraph';
import HeaderWrapper from '@/components/redesign/materials/view/modules/Header';
import Delimiter from '@/components/redesign/materials/view/modules/Delimiter';
import List from '@/components/redesign/materials/view/modules/List';
import LinkTool from '@/components/redesign/materials/view/modules/LinkTool';
import Attaches from '@/components/redesign/materials/view/modules/Attaches';
import Quote from '@/components/redesign/materials/view/modules/Quote';
import ImageWrapper from '@/components/redesign/materials/view/modules/ImageWrapper';
import EmbedData from '@/components/redesign/materials/view/modules/Embed';
import Table from '@/components/redesign/materials/view/modules/Table';

export default {
  name: 'MaterialView',
  components: {
    Paragraph,
    HeaderWrapper,
    Delimiter,
    List,
    LinkTool,
    Attaches,
    Quote,
    ImageWrapper,
    EmbedData,
    Table,
  },
  props: {
    body: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    content() {
      return [...this.body].map((item) => {
        let type = item.type
        if (item.type === 'header') type = 'HeaderWrapper'
        if (item.type === 'image') type = 'ImageWrapper'
        if (item.type === 'embed') type = 'EmbedData'
        if (item.type === 'Table') type = 'TableWrapper'

        return {
          ...item,
          type
        };
      });
    }
  }
}
</script>

<style lang="scss">
p {
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500 !important;
  margin-bottom: 20px !important;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}
</style>
