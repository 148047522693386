<template>
  <div class="relative overflow-x-auto !mb-4">
    <table
      class="table-auto w-full text-sm text-left rtl:text-right text-[#5c5c5c]"
    >
      <tbody>
        <tr
          v-for="(row, rowIndex) in content"
          :key="`row-${rowIndex}`"
          class="border-b border-[#dbdbdb]"
        >
          <td
            v-for="(cell, cellIndex) in row"
            :key="`cell-${rowIndex}-${cellIndex}`"
            class="!px-6 !py-4"
          >
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UTable',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    content() {
      return this.data.data.content;
    },
  },
};
</script>
