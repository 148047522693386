<template>
  <a class="link" :href="data.data.link" target="_blank">{{
    data.data.link
  }}</a>
</template>

<script>
export default {
  name: 'LinkTool',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: rgb(0, 76, 255);
}
</style>
