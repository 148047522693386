<template>
  <a class="attaches" :href="file.url" target="_blank">
    <div class="ext" :class="[file.extension]">
      {{ extension }}
    </div>

    <div class="info">
      <div class="title">{{ file.title }}</div>
      <div class="size">{{ size }}MB</div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'AttachesModules',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      file: {},
    };
  },
  computed: {
    size() {
      return (this.file.size / 1024 / 1024).toFixed(2);
    },
    extension() {
      const ext = this.file.extension?.trim();
      if (ext === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return 'xlsx';
      }

      if (
        ext === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        return 'docx';
      }

      return ext;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.file = this.data.data.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.attaches {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  border: 1px solid #c0c0c054;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;

  .ext {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #006cb7;
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 12px;
    font-size: 13px;
    &.pdf {
      background: rgb(219, 47, 47);
    }
  }

  .title {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .size {
    font-size: 14px;
    color: #bbbbbb;
  }
}
</style>
