<template>
  <div class="delimiter">***</div>
</template>

<script>
export default {
  name: 'EditorDelimiter',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.delimiter {
  text-align: center;
  letter-spacing: 4px;
  font-size: 35px;
  color: #b1b1b1;
}
</style>
