<template>
  <div>
    <h1
      v-if="level === 1"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h1>
    <h2
      v-if="level === 2"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h2>
    <h3
      v-if="level === 3"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h3>
    <h4
      v-if="level === 4"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h4>
    <h5
      v-if="level === 5"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h5>
    <h6
      v-if="level === 6"
      v-html="text"
      :style="{ 'text-align': alignment }"
    ></h6>
  </div>
</template>

<script>
export default {
  name: 'EditorHeader',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    level() {
      return this.data.data.level;
    },
    text() {
      return this.data.data.text;
    },
    alignment() {
      return this.data.tunes.alignment.alignment;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 15px;
}
</style>
