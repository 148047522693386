<template>
  <div class="utags">
    <div class="add-tag">
      <input type="text" v-model="tag" placeholder="Напишите тег" v-on:keyup.enter="addTag" />
      <UButton @click="addTag">
        Добавить
      </UButton>
    </div>
    <div class="list custom-scrollbar-vertical">
      <div
        v-for="(item, index) of tags"
        :key="index"
        class="tag"
        @click="deleteTag(index)"
      >
        <div class="title">{{ item }}</div>
        <UIcon name="close" />
      </div>
    </div>
  </div>
</template>

<script>
import UButton from '@/components/redesign/ui/UButton'

export default {
  name: 'UTags',
  components: { UButton },
  props: {
    value: {
      type: Array,
      default: () => {}
    },
  },
  data() {
    return {
      tag: '',
    };
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    addTag() {
      if (this.tag) {
        this.tags.push(this.tag);
        this.tag = '';
      }
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    },
  },
}
</script>

<style lang="scss">
.utags {
  display: flex;
  align-items: center;

  .add-tag {
    display: flex;
    align-items: center;
    height: 36px;
    margin-right: 16px;
    padding-bottom: 10px;

    .button {
      height: 36px;
      border-radius: 0 6px 6px 0;
    }

    input {
      height: 36px;
      padding: 0 12px;
      border-radius: 6px 0 0 6px;
      max-width: 150px;
    }
  }

  .list {
    display: flex;
    max-width: 580px;
    overflow: auto;
    padding-bottom: 10px;


    .tag {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 36px;
      background: #DFEDF4;
      color: #0088CC;
      border-radius: 6px;
      margin-right: 12px;
      cursor: pointer;

      .title {
        font-size: 14px;
        margin-right: 12px;
      }

      .icon {
        width: 13px;
        height: 13px;
        fill: #0088CC;
      }
    }
  }
}
</style>
