<template>
  <div class="embed-video">
    <embed :src="data.data.embed" />
    <div v-if="data.data.caption" class="caption">
      {{ data.data.caption }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmbedModules',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-video {
  .caption {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    background: #dfedf4;
    width: max-content;
    padding: 6px 15px;
    border-radius: 10px;
    color: var(--main);
    margin: 0 auto;
  }

  embed {
    max-width: 500px;
    width: 500px;
    height: 400px;
    margin: 16px auto;
    text-align: center;
    display: flex;
  }
}
</style>
